
export default {
  name: 'NavigationResponsive',
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ '../../../components/ui/Logo'),
    ButtonBurgerMenu: () => import(/* webpackChunkName: "ButtonBurgerMenu" */ '../../../components/ui/form/buttons/ButtonBurgerMenu'),
    NavigationDrawer: () => import(/* webpackChunkName: "NavigationDrawer" */ './NavigationDrawer')
  },
  data: () => ({
    isDrawerOpen: false,
    showDrawer: true
  }),
  computed: {
    isTab () { return this.$store.state.store.isTab }
  },
  watch: {
    $route (to, from) {
      this.close()
    }
  },
  methods: {
    close () {
      this.hideDrawer()
    },
    toggleDrawer () {
      this.isDrawerOpen ? this.hideDrawer() : this.openDrawer()
    },
    openDrawer () {
      if (!this.$refs.navDrawer) { return }
      if (this.$refs.navDrawer) { this.$refs.navDrawer.style.left = '0' }
      this.isDrawerOpen = true
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('body').style.position = 'fixed'
    },
    hideDrawer () {
      if (!this.$refs.navDrawer) { return }
      setTimeout(() => {
        if (this.$refs.navDrawer) { this.$refs.navDrawer.style.left = '-100vw' }
        this.isDrawerOpen = false
      }, 100)
      document.querySelector('body').style.overflow = 'clip'
      document.querySelector('body').style.position = 'relative'
    }
  }
}
